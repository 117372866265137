import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Image from 'core/image';
import { Container, BadgeBox, BadgesRowContainer } from './styles';
import PageSection from 'atomic-design/atoms/page-section';

const BadgesContainer = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://widget.clutch.co/static/js/widget.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.CLUTCHCO.Init();
    };

    return () => {
      window.CLUTCHCO.Destroy();
      document.body.removeChild(script);
    };
  }, []);
  return (
    <PageSection containerSize="fluid" options="fullWidth">
      <Container>
        <BadgesRowContainer>
          <BadgeBox key="badge-champ" widthPercentage={'30%'}>
            <Image partialPathName="clutch-badge-champion" alt="badge-champ" width="100%" />
          </BadgeBox>
          <BadgeBox key="badge-global" widthPercentage={'30%'}>
            <Image partialPathName="clutch-badge-global" alt="badge-global" width="100%" />
          </BadgeBox>
          <BadgeBox key="badge-aws" widthPercentage={'30%'}>
            <Image partialPathName="clutch-badge-aws-partner" alt="badge-aws" width="90%" />
          </BadgeBox>
          <BadgeBox key="badge-databricks" widthPercentage={'30%'}>
            <Image
              partialPathName="clutch-badge-databricks-partner"
              alt="badge-databricks"
              width="100%"
            />
          </BadgeBox>
          <BadgeBox key="review" widthPercentage={'44%'}>
            <div
              class="clutch-widget"
              data-url="https://widget.clutch.co"
              data-widget-type="14"
              data-height="50"
              data-nofollow="true"
              data-expandifr="true"
              data-scale="100"
              data-clutchcompany-id="155710"
            ></div>
          </BadgeBox>
        </BadgesRowContainer>
      </Container>
    </PageSection>
  );
};

BadgesContainer.propTypes = {
  href: PropTypes.string,
  key: PropTypes.string,
  rel: PropTypes.string,
  contentfulId: PropTypes.string,
  alt: PropTypes.string,
};

BadgesContainer.defaultProps = {
  href: '',
  key: '',
  rel: '',
  contentfulId: '',
  alt: '',
};

export default BadgesContainer;
