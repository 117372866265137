import React, { useEffect, useRef } from 'react';

const HtmlParser = ({ html }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const div = document.createElement('div');
    div.innerHTML = html;

    containerRef.current.innerHTML = '';

    const isIframe = html.trim().startsWith('<iframe');

    if (isIframe) {
      const blob = new Blob([html], { type: 'text/html' });
      const url = URL.createObjectURL(blob);

      const iframe = document.createElement('iframe');
      iframe.src = url;
      iframe.width = '100%';
      iframe.height = '544';
      iframe.frameBorder = '0';

      containerRef.current.appendChild(iframe);

      return () => {
        URL.revokeObjectURL(url);
      };
    } else {
      containerRef.current.appendChild(div);
    }
  }, [html]);

  return <div ref={containerRef} />;
};

export default HtmlParser;
