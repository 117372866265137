import React from 'react';
import PropTypes from 'prop-types';
import {
    TableWrapper,
    TableHead,
    TableBody,
    TableRow,
    TableHeader,
    TableCell,
} from './styles';

const Table = ({ headers, rows }) => {
    return (
        <TableWrapper>
            <TableHead>
                <TableRow>
                    {headers.map((header, index) => (
                        <TableHeader key={index}>{header}</TableHeader>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, rowIndex) => (
                    <TableRow key={`row-${rowIndex}`}>
                        {row.map((cell, cellIndex) => (
                            <TableCell key={`cell-${cellIndex}`}>{cell}</TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </TableWrapper>
    )
};

Table.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default Table;