import styled from 'styled-components';

export const PageHeaderContainer = styled.section`
  width: 100%;

  b {
    display: block;
  }
`;

export const ButtonTeamContainer = styled.div`
  width: 100%;
  display: flex;
  padding-top: ${({ theme }) => theme.newTheme.spacing(12)};
  padding-bottom: ${({ theme }) => theme.newTheme.spacing(6)};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    padding-top: ${({ theme }) => theme.newTheme.spacing(6)};
  }
`;

export const Span = styled.span`
  font-weight: ${({ theme }) => theme.newTheme.font.weight.light};
  display: block;
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(1)};
`;

export const SectionTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h3 {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    margin-top: ${({ theme }) => theme.newTheme.spacing(10)};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.title4.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
      margin-top: ${({ theme }) => theme.newTheme.spacing(6)};
    }
  }

  h3 span {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    background: ${({ theme }) => `${theme.newTheme.color.gradient.text}`};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.title4.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    }
  }

  h4 {
    margin-bottom: 0;
  }
`;

export const NaNList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const NaNListItem = styled.li`
  margin-bottom: ${({ theme }) => `${theme.newTheme.spacing(16)}`};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin-bottom: ${({ theme }) => `${theme.newTheme.spacing(2)}`};
  }

  img {
    width: 100%;
  }
`;

export const CheckListSectionList = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CheckListSectionItem = styled.div``;

export const ChecklistCol = styled.div`
  padding-left: 30px !important;
  padding-right: 50px !important;
`;

export const ThoughtH5 = styled.h5`
  font-size: ${({ theme }) => `${theme.newTheme.font.title2.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.title2.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.newTheme.font.weight.bold}`};
  color: ${({ theme }) => `${theme.newTheme.color.white}`};
  margin-top: 0;
  width: 75%;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => `${theme.newTheme.font.title3.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title3.lineHeight}`};
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(4)};
  }
`;

export const ThoughtP = styled.p`
  font-size: ${({ theme }) => `${theme.newTheme.font.text1.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.text1.lineHeight}`};
  color: ${({ theme }) => `${theme.newTheme.color.white}`};
  margin: 0;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => `${theme.newTheme.font.text2.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.text2.lineHeight}`};
  }
`;

export const TeamContainer = styled.section`
  h3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: ${({ theme }) => theme.newTheme.spacing(4)} !important;

    span {
      margin: 0 10px;
    }

    span:nth-child(2) {
      font-size: ${({ theme }) => `${theme.newTheme.font.title3.size}`};
      line-height: ${({ theme }) => `${theme.newTheme.font.title3.lineHeight}`};
      font-weight: ${({ theme }) => `${theme.newTheme.font.weight.bold}`};
      background: ${({ theme }) => `${theme.newTheme.color.black}`};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
        font-size: ${({ theme }) => `${theme.newTheme.font.title4.size}`};
        line-height: ${({ theme }) => `${theme.newTheme.font.title4.lineHeight}`};
        display: block;
      }
    }

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      display: block;

      span:nth-child(1),
      span:nth-child(2) {
        margin: 0;
      }
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    display: inherit;
  }
`;

export const TeamTitle = styled(SectionTitle)`
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  h3 {
    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      span:nth-child(1) {
        display: block;
      }
    }
  }
`;

export const TeamList = styled.div`
  width: 100%;
  padding: 0;
`;

export const CallToActionContainer = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => `${theme.newTheme.spacing(16)}`};

  section {
    padding-bottom: ${({ theme }) => `${theme.newTheme.spacing(8)}`};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      padding-top: 0 !important;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin-bottom: 0;
  }
`;

export const MissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme }) => theme.newTheme.spacing(16)};
  padding-bottom: ${({ theme }) => theme.newTheme.spacing(40)};

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => `${theme.newTheme.spacing(16)} 0`};
  }
`;

export const MissionSectionBackground = styled.div`
  left: 0;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 400px;
  background: ${({ theme }) => `${theme.newTheme.color.primary.light}20`};
  transform: skewY(-8deg);
`;

export const MissionBoxContainer = styled.div`
  position: relative;
  z-index: 9;
  width: 70%;
  height: 50%;
  max-width: 800px;
  margin: ${({ theme }) => theme.newTheme.spacing(16)} auto;
  border-radius: ${({ theme }) => theme.newTheme.border.radius.main};
  box-shadow: ${({ theme }) => theme.newTheme.shadow.main};
  background-color: ${({ theme }) => theme.newTheme.color.white};
  padding: ${({ theme }) => `${theme.newTheme.spacing(16)}`};

  p {
    font-size: ${({ theme }) => `${theme.newTheme.font.title6.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title6.lineHeight}`};
    margin-bottom: 0;
    b {
      font-weight: ${({ theme }) => `${theme.newTheme.font.weight.bold}`};
    }

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => `${theme.newTheme.font.text1.size}`};
      line-height: ${({ theme }) => `${theme.newTheme.font.text1.lineHeight}`};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
    padding: ${({ theme }) => `${theme.newTheme.spacing(12)}  ${theme.newTheme.spacing(4)}`};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 100%;
    padding: ${({ theme }) => `${theme.newTheme.spacing(12)} ${theme.newTheme.spacing(8)}`};
  }
`;

export const MissionH4 = styled.h4`
  width: 100%;
  font-size: ${({ theme }) => `${theme.newTheme.font.title3.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.title3.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.newTheme.font.weight.bold}`};
  background: ${({ theme }) => theme.newTheme.color.gradient.text};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: ${({ theme }) => theme.newTheme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(2)};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => `${theme.newTheme.font.title5.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title5.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`};
  }
`;

export const GreyH5 = styled.h5`
  font-size: ${({ theme }) => `${theme.newTheme.font.text2.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.text2.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.newTheme.font.weight.regular}`};
  color: ${({ theme }) => `${theme.newTheme.color.grey.light}`};
  margin: 0;
`;

export const ManifestoList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const ContainerGrid = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.newTheme.spacing(2)} 0 !important`};
  margin: 0 !important;
`;

export const CenterLastElement = styled.div`
  justify-content: center !important;
  align-items: center !important;
`;

export const ManifestoTitle = styled.h3`
  font-size: ${({ theme }) => `${theme.newTheme.font.title4.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.title4.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.newTheme.font.weight.bold}`};
`;

export const ManifestoText = styled.p`
  margin-bottom: ${({ theme }) => `${theme.newTheme.spacing(12)}`};
  span {
    display: block;
  }
`;

export const Content = styled.div`
  margin: ${({ theme }) => theme.newTheme.spacing(16)} auto;
  h5 {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.title6.size};
      line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
      position: relative;
      z-index: 9;

      b {
        display: block;
      }
    }
  }
  svg {
    opacity: 0.5;

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      position: absolute;
      z-index: 0;
      transform: translateX(-100px) translateY(-30px);
    }
  }
  hr {
    background-color: ${({ theme }) => theme.newTheme.color.divider};
  }
  q {
    font-size: ${({ theme }) => theme.newTheme.font.text1.size};
    line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.text2.size};
      line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin: ${({ theme }) => theme.newTheme.spacing(26)} auto;
  }
`;
