import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../../../styles/theme';

import richTextOptions from '../../../../common/rich-text-options';
import PageSection from '../page-section';
import RichTextWrapper from '../../organisms/rich-text-wrapper';

const LandingPageSection = ({ fields }) => {
  const background = fields.background
    ? fields.background['en-US']
    : `${theme.newTheme.color.primary.light}20`;
  const size = fields.layoutSize ? fields.layoutSize['en-US'] : 'sm';

  return (
    <PageSection
      containerSize={size}
      options="fullWidth"
      background={background}
      padding={`${theme.newTheme.spacing(4.5)} 0 ${theme.newTheme.spacing(13)} 0`}
    >
      <RichTextWrapper
        json={fields.body['en-US']}
        options={richTextOptions}
        firstLetter="normal"
        color={theme.newTheme.color.primary.main}
      />
    </PageSection>
  );
};

LandingPageSection.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default LandingPageSection;
