import React from 'react';
import PropTypes from 'prop-types';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { StyledWrapper, StyledHeadersWrapper } from './styles';

const RichTextWrapper = ({ color, json, options, firstLetter }) => {
  const renderNodes = () => {
    if (json.content[0].content.nodeType === 'paragraph') {
      return (
        <>
          <StyledWrapper color={color} firstLetter={firstLetter}>
            {documentToReactComponents({ data: json.data, content: [json.content[0]] }, options)}
          </StyledWrapper>
          <StyledHeadersWrapper color={color}>
            {documentToReactComponents(
              { data: json.data, content: json.content.slice(1) },
              options
            )}
          </StyledHeadersWrapper>
        </>
      );
    }
    return (
      <>
        <StyledWrapper color={color} firstLetter={firstLetter}>
          {documentToReactComponents(
            { data: json.data, content: json.content.slice(0, 2) },
            options
          )}
        </StyledWrapper>
        <StyledHeadersWrapper color={color}>
          {documentToReactComponents({ data: json.data, content: json.content.slice(2) }, options)}
        </StyledHeadersWrapper>
      </>
    );
  };
  return <>{renderNodes()}</>;
};

RichTextWrapper.propTypes = {
  color: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  json: PropTypes.shape({
    data: PropTypes.instanceOf(Object),
    content: PropTypes.arrayOf(Object),
  }).isRequired,
  firstLetter: PropTypes.oneOf(['colored', 'normal']),
};

RichTextWrapper.defaultProps = {
  firstLetter: 'colored',
};

export default RichTextWrapper;
