import React from 'react';
import PropTypes from 'prop-types';
import Gist from 'react-gist';
import ClutchReview from 'atomic-design/molecules/clutch-review';
import BadgesContainer from 'atomic-design/molecules/badges-container';
import BadgesAwsContainer from 'atomic-design/molecules/badges-aws-container';
import ArticleAndImage from 'atomic-design/organisms/article-and-image';
import ServicesSlider from 'atomic-design/organisms/services-slider';
import TitleAndSubtitle from '../title-and-subtitle';
import AboutUsInfo from 'atomic-design/organisms/about-us-info';
import HtmlParser from 'atomic-design/molecules/html-parser';
import TeamMemberList from 'atomic-design/molecules/team-member-list';

const CodeParser = props => {
  const { text } = props;

  // Regex to validate that it's an HTML tag like <div> or <iframe>
  const htmlRegex = /<([\w-]+)(?:\s*([^>]*)>([^<]*)<\/\1>|(\s*\/>))/gi;

  if (typeof text === 'string') {
    if (text.startsWith('html-raw#')) {
      const cleanText = text.replace('html-raw#', '').trim();
      if (htmlRegex.test(cleanText)) {
        return <HtmlParser html={cleanText} />;
      }
    }
    switch (true) {
      case text.startsWith('gist#'):
        return <Gist id={text.replace('gist#', '')} />;
      case text.startsWith('<div class="clutch-widget"'):
        return <ClutchReview component={text} />;
      case text.startsWith('badges-container#'):
        return <BadgesContainer />;
      case text.startsWith('badges-aws-container#'):
        return <BadgesAwsContainer />;
      case text.startsWith('about-us#'):
        return <AboutUsInfo />;
      case text.startsWith('article-and-image#'):
        return <ArticleAndImage />;
      case text.startsWith('services-slider#'):
        return <ServicesSlider />;
      case text.startsWith('powering-software-team-title-and-subtitle#'):
        return (
          <TitleAndSubtitle title="Powering software teams," subtitle="from series A to IPO" />
        );
      case text.startsWith('team-member-list#'):
        return <TeamMemberList />;
    }
  }

  return <code>{text}</code>;
};

CodeParser.propTypes = {
  text: PropTypes.string.isRequired,
};

CodeParser.defaultProps = {
  text: '',
};

export default CodeParser;
