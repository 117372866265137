import React from 'react';

import Image from 'core/image';
import useIsMobile from 'hooks/useIsMobile';
import {
  InformationContainer,
  ImgContainer,
  PreTitle,
  GradientTitle,
  Description,
  Subtitle,
  Container,
} from './styles';

const ArticleAndImage = () => {
  const isTablet = useIsMobile('tablet');

  return (
    <Container>
      <InformationContainer>
        <PreTitle>WHAT SETS US APART</PreTitle>
        <GradientTitle>The NaNLABS Way</GradientTitle>
        <Subtitle>Blending Your Vision With Our Expertise.</Subtitle>
        <Description>
          {
            'Our superpower? A strong commitment to delivering high-quality, fast and cost-effective software solutions. When we team up, you get a dedicated Agile squad that crafts tech marvels, while we align with your timezone for seamless collaboration and rapid response times.'
          }
          <br />
          <b>{' A true sidekick.'}</b>
        </Description>
      </InformationContainer>
      <ImgContainer>
        <Image
          alt="an Image"
          partialPathName="four-spheres-image"
          width={isTablet ? '75%' : '100%'}
          fluid
        />
      </ImgContainer>
    </Container>
  );
};

export default ArticleAndImage;
