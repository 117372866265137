import React from 'react';
import PropTypes from 'prop-types';

import { ClientTitle } from './styles';

const TitleAndSubtitle = ({ title, subtitle }) => {
  return (
    <ClientTitle>
      <span>{title}</span>
      <span>{subtitle}</span>
    </ClientTitle>
  );
};

TitleAndSubtitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default TitleAndSubtitle;
