import styled from 'styled-components';
import theme from 'styles/theme';

export const TableWrapper = styled.table`
  border-collapse: collapse;
  margin: ${theme.newTheme.spacing(12)} 0;
  width: 100%;
  @media screen and (max-width: 768px) {
    /* Styles for smaller screens */
    display: block;
    overflow-x: auto; /* Enable horizontal scrolling for smaller screens */
  }
`;

export const TableHead = styled.thead`
  background-color: #f8f9fa;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  border-bottom: 1px solid #dee2e6;
`;

export const TableHeader = styled.th`
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  font-size: ${theme.newTheme.font.text2.size};
  padding: 12px;
  text-align: center;
  
  &:first-child {
    text-align: left;
    border-left: 1px solid #dee2e6;
  }
`;

export const TableCell = styled.td`
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  font-size: ${theme.newTheme.font.text2.size};
  padding: 12px;
  text-align: center;
  
  &:first-child {
    border-left: 1px solid #dee2e6;
    text-align: left;
  }
`;