import styled, { css } from 'styled-components';

const alignImgStyles = (theme, imgAlign) =>
  ({
    start: css`
      flex-direction: row;
    `,
    center: css`
      flex-direction: column;
      span {
        margin-bottom: ${theme.newTheme.spacing(6)};
      }
    `,
  }[imgAlign]);

const alignTextStyles = textAlign =>
  ({
    start: css`
      text-align: start;
    `,
    center: css`
      text-align: center;
    `,
    end: css`
      text-align: end;
    `,
  }[textAlign]);

export const Container = styled.div`
  width: 100%;
  ${({ isBlockQuote, theme }) =>
    isBlockQuote &&
    css`
      margin: ${theme.spacing.longSM} 0;
      padding: 0 ${theme.spacing.medium};
      border-left: 3px solid ${theme.colors.primary};
    `}
  svg {
    opacity: 0.5;
    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      position: absolute;
      z-index: 0;
      transform: translateX(-100px) translateY(0);
    }
  }
`;

export const Content = styled.div`
  ${({ theme, imgAlign }) => alignImgStyles(theme, imgAlign)};
  ${({ textAlign }) => alignTextStyles(textAlign)};
`;

export const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

export const AuthorText = styled.figcaption`
  margin-left: ${({ theme }) => theme.newTheme.spacing(6)};
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  font-size: ${({ theme }) => theme.newTheme.font.text2.size};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};

  span {
    color: ${({ nameColor }) => nameColor};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
    font-size: ${({ theme }) => theme.newTheme.font.title6.size};
    padding-bottom: ${({ theme }) => theme.newTheme.spacing(2)};
    display: block;
    text-align: start;

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.text1.size};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text3.size};
    text-align: start;
  }
`;

export const ImgContainer = styled.div`
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-color: ${({ theme }) => theme.newTheme.color.grey.light};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 50%;
    transform: scale(1.5);
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    max-width: 120px;
    max-height: 120px;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 50%;
    height: 50%;
    overflow: hidden;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  text-align: start;
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  margin: 0;

  h5 {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(2)};

    b {
      background: ${({ theme }) => theme.newTheme.color.gradient.text};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
    }
  }
`;

export const DescriptionContainer = styled.blockquote`
  margin: 0;
`;

export const Description = styled.p`
  padding: 0;
  margin-bottom: ${({ theme, isBlockQuote }) =>
    isBlockQuote ? theme.spacing.medium : theme.newTheme.spacing(10)} !important;
  p {
    ${({ isBlockQuote }) =>
      isBlockQuote &&
      css`
        margin-bottom: 0 !important;
      `}
  font-style: italic;
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  padding: ${({ theme }) => theme.newTheme.spacing(4)} 0;
  text-align: start;
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
  }
`;

export const Quotes = styled.div`
  transform: translateY(-15px);
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    transform: translateY(-55px) translateX(10px);
  }
`;

export const ContainerWithoutTitle = styled.div`
  display: flex;

  p {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(10)};
    font-style: italic;
  }
  svg {
    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      width: 0;
      height: 0;
    }
  }
`;
