import React from 'react';
import { LinkedInIconOutline } from 'ui/svg';

import TeamItem from 'atomic-design/molecules/team-item';
import theme from 'styles/theme';

import { Container, Row, Col } from 'react-grid-system';
import { useTeamData } from 'hooks/useTeamData';

import { ContainerGrid, TeamList } from 'styles/pages/about-us-styles';

const TeamMemberList = () => {
  const teamMembers = useTeamData();
  const teamMembersData = teamMembers.filter(
    edge => edge.node.pageToRender && edge.node.pageToRender.includes('aws-landing')
  );

  const iconArgs = {
    size: theme.newTheme.spacing(12),
    color: theme.newTheme.color.primary.main,
  };
  const linkedin = {
    name: 'LinkedIn',
    icon: <LinkedInIconOutline {...iconArgs} />,
  };

  return (
    <Container fluid={true} component={ContainerGrid}>
      <TeamList>
        <Row component={ContainerGrid}>
          {teamMembersData.map((teamMember, index) => (
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              key={`Column-${teamMember.node.name}`}
              component={ContainerGrid}
            >
              <TeamItem
                image={teamMember.node.image.contentful_id}
                title={teamMember.node.name}
                role={teamMember.node.role}
                description={teamMember.node.description}
                skillList={teamMember.node.skills}
                socialList={[{ ...linkedin, to: teamMember.node.linkedinUrl }]}
                leftImage={index % 2 === 0}
              />
            </Col>
          ))}
        </Row>
      </TeamList>
    </Container>
  );
};

export default TeamMemberList;
