import styled, { css } from 'styled-components';

const fullWidthStyle = fullWidth =>
  ({
    true: css`
      width: 100vw;
      left: calc(-50vw + 50%);
    `,
    default: css`
      width: 100%;
    `,
  }[fullWidth]);

export const Container = styled.div`
  margin: ${({ margin }) => margin};
  ${({ fullWidth }) => fullWidthStyle(fullWidth)};
  overflow: hidden;
  position: relative;
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    margin: ${({ mobileMargin }) => mobileMargin};
  }
`;

export const Background = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  border-top: ${({ theme }) => `${theme.newTheme.spacing(23)} solid white`};
  border-right: 100vw solid transparent;
`;

export const Text = styled.h3`
  position: relative;
  z-index: 9;
  width: 100%;
  font-size: ${({ theme }) => `${theme.newTheme.font.title3.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.title3.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`};

  span {
    font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`};

    background: ${({ theme }) => `${theme.newTheme.color.gradient.text}`};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
      font-size: ${({ theme }) => `${theme.newTheme.font.title5.size}`};
      line-height: ${({ theme }) => `${theme.newTheme.font.title5.lineHeight}`};
      display: block;
    }
  }

  p {
    font-size: ${({ theme }) => `${theme.newTheme.font.title3.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title3.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`};

    ::first-letter {
      font-size: ${({ theme }) => `${theme.newTheme.font.title3.size}`} !important;
      line-height: ${({ theme }) => `${theme.newTheme.font.title3.lineHeight}`} !important;
      font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`} !important;
    }
    b {
      font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`};
      background: ${({ theme }) => `${theme.newTheme.color.gradient.text}`};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      ::first-letter {
        font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`};
        background: ${({ theme }) => `${theme.newTheme.color.gradient.text}`};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
      font-size: ${({ theme }) => `${theme.newTheme.font.title5.size}`};
      line-height: ${({ theme }) => `${theme.newTheme.font.title5.lineHeight}`};
      display: block;
      ::first-letter {
        font-size: ${({ theme }) => `${theme.newTheme.font.title5.size}`} !important;
        line-height: ${({ theme }) => `${theme.newTheme.font.title5.lineHeight}`} !important;
      }
      b {
        font-size: ${({ theme }) => `${theme.newTheme.font.title5.size}`};
        line-height: ${({ theme }) => `${theme.newTheme.font.title5.lineHeight}`};
        display: block;
        padding: 0;
        ::first-letter {
          font-size: ${({ theme }) => `${theme.newTheme.font.title5.size}`};
          line-height: ${({ theme }) => `${theme.newTheme.font.title5.lineHeight}`};
        }
      }
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => `${theme.newTheme.font.title5.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title5.lineHeight}`};
    width: 100%;
  }
`;
