import styled from 'styled-components';

const ChipStyled = styled.div`
  display: flex;
  margin: ${props => (props.margin ? props.margin : '')};
  align-content: center;
  justify-content: center;
  width: fit-content;
  background-color: ${props => props.color};
  z-index: 2;
  border-radius: 90px;
  padding: 3px 13px;
  color: ${props => props.theme.colors.white};

  &:hover {
    background-color: ${props => props.hoverColor};
  }

  * {
    font-family: ${({ theme }) => theme.fonts.family.primary};
  }

  a {
    color: white;
    text-decoration: none;
    border-bottom: 1px transparent solid;
    font-size: 16px;
    font-weight: ${props => props.theme.fonts.weights.light};

    &:hover {
      border-bottom: 1px ${props => props.theme.colors.white} solid;
    }
  }

  @media only screen and (${props => props.theme.breakpoints.mobile}) {
    align-self: right;
  }
`;

export default ChipStyled;
