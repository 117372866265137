import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: ${({ theme }) => theme.newTheme.spacing(10)} 0;
  gap: ${({ theme }) => theme.newTheme.spacing(20)};
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.newTheme.spacing(2)};
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-basis: 45%;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    width: 100%;
    height: 100%;
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    div {
      div {
        margin: 0 auto;
      }
    }
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-basis: 55%;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.newTheme.spacing(1)};
`;

export const PreTitle = styled.h3`
  font-size: ${({ theme }) => theme.newTheme.font.text3.size};
  line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular} !important;
  letter-spacing: 2.72px;
  color: ${({ theme }) => theme.newTheme.color.primary.main} !important;
  margin: 0;
  padding: 0 !important;
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.text4.size};
  }
`;

export const GradientTitle = styled.h2`
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold} !important;
  padding: ${({ theme }) => theme.newTheme.spacing(10)} 0
    ${({ theme }) => theme.newTheme.spacing(4)} !important;
  margin: 0 !important;
  background: ${({ theme }) => theme.newTheme.color.gradient.background.primary.main};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 100%;
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    padding: ${({ theme }) => theme.newTheme.spacing(5)} 0
      ${({ theme }) => theme.newTheme.spacing(2)} !important;
  }
`;

export const Subtitle = styled.h4`
  font-size: ${({ theme }) => theme.newTheme.font.title5.size};
  line-height: ${({ theme }) => theme.newTheme.font.title5.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  margin: 0 !important;
  color: ${({ theme }) => theme.newTheme.color.black};
  padding: ${({ theme }) => theme.newTheme.spacing(3)} 0 !important;
  width: 85%;
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.title6.size};
    line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
    padding: ${({ theme }) => theme.newTheme.spacing(3)} 0 !important;
    width: 100%;
  }
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
  color: ${({ theme }) => theme.newTheme.color.grey.main};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text3.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  }
`;
