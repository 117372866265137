import styled from 'styled-components';

export const ContainerCallToAction = styled.section`
  width: 100%;
  padding: ${({ theme }) => `${theme.newTheme.spacing(10)} 0`};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    box-shadow: ${({ theme }) => theme.newTheme.shadow.main};
    ${({ theme }) => `padding: ${theme.newTheme.spacing(3)} ${theme.newTheme.spacing(10)}`};

    a {
      color: ${({ theme }) => theme.newTheme.color.white};
      text-decoration: none;
    }
  }
`;

export const CallToActionSectionTitle = styled.h4`
  font-size: ${({ theme }) => theme.newTheme.font.title4.size};
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.medium};
  text-align: center;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title5.size};
  }
`;
