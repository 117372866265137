import styled from 'styled-components';

export const ClientTitle = styled.h4`
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  color: ${({ theme }) => theme.colors.black};
  margin: ${({ theme }) => theme.newTheme.spacing(28)} 0 ${({ theme }) => theme.newTheme.spacing(9)};
  width: 100%;

  span {
    &:nth-child(1) {
      font-weight: ${({ theme }) => theme.fonts.weights.heavy};
      margin-right: ${({ theme }) => theme.newTheme.spacing(3)};
      color: ${({ theme }) => theme.colors.black};
    }

    &:nth-child(2) {
      background: ${({ theme }) => theme.gradients.background};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: ${({ theme }) => theme.fonts.weights.heavy};
      display: block;
      margin-bottom: ${({ theme }) => theme.newTheme.spacing(12)};

      @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
        margin-bottom: ${({ theme }) => theme.newTheme.spacing(7)};
      }
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    margin-top: ${({ theme }) => theme.newTheme.spacing(10)};

    span {
      display: block;
    }
  }
`;
