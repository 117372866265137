const servicesSliderTabs = [
  {
    tabName: 'Software Development Outsourcing',
    content: {
      title:
        'Experience technical brilliance with our full-stack E2E software development outsourcing service.',
      cta: {
        promptText: null,
        ctaButtonLabel: 'Find Out More!',
        url: '/',
      },
      description: [
        'Spanning Javascript, Python, Next.JS, Node.JS, and React Native, our commitment to excellence guarantees accessibility and adherence to industry best practices, crafting exceptional digital products from concept to deployment and beyond.',
      ],
      bulletList: [
        '<b>Conceptualization and Ideation<b>',
        '<b>Market Research<b>',
        '<b>Design and Prototyping<b>',
        '<b>Development<b>',
        '<b>Quality Assurance and Testing<b>',
        '<b>User Testing and Feedback<b>',
        '<b>Iterative Development<b>',
        '<b>Deployment<b>',
        '<b>Ongoing Support and Maintenance<b>',
      ],
    },
  },
  {
    tabName: 'Team Augmentation',
    content: {
      title: 'Get support from a dedicated Agile squad of experts to accelerate your growth.',
      cta: {
        promptText: null,
        ctaButtonLabel: 'Solve it now!',
        url: '/services/team-augmentation/',
      },
      description: [
        'Get a tailored & fully dedicated expert development team that will  seamlessly blend with your own team to power up your development process and achieve your goals on time.',
      ],
      bulletList: [
        '<b>Agility</b> to help you swiftly scale your dev teams and adapt to changing project requirements.',
        'A culture of <b>innovation and problem-solving</b>, ensuring efficient processes for rapid feature releases and structured project kick-offs.',
        'An unwavering <b>commitment to security, quality, and open communication</b>.',
        'Join a legacy of <b>successful partnerships</b> with clients like HyreCar, Amalgam, WootCloud, and Equinix, among others.',
      ],
    },
  },
  {
    tabName: 'Tech Consultancy',
    content: {
      title: "Struggling to unlock your technology's full potential? Borrow our brains!",
      cta: {
        promptText: null,
        ctaButtonLabel: 'Talk to an expert!',
        url: '/services/consultancy/',
      },
      description: [
        'As your trusty sidekick, we harness our expertise to help you solve complex technical issues. With an Agile & innovative mindset, we dive deep into your business so you can make informed decisions and unlock the full potential of your technology and software development endeavors.',
      ],
      bulletList: [
        '<b>Software Architecture Consultancy</b>',
        '<b>Data Engineering Consultancy</b>',
        '<b>Research & Development Consultancy</b>',
        '<b>Rapid Prototyping</b>',
      ],
    },
  },
  {
    tabName: 'Staffing',
    content: {
      title:
        'Craft your ideal team with our Premier Talent Hub of tech and industry-savvy experts.',
      cta: {
        promptText: null,
        ctaButtonLabel: "Let's talk!",
        url: '/contact/',
      },
      description: [
        'With an extensive talent pool of tech professionals in Latin America, we can help you build your dream team.',
      ],
      bulletList: [
        '<b>Personalized talent curation:</b> going beyond matching resumes to job descriptions, we curate talent, considering individuality to ensure the perfect fit for your project.',
        '<b>Deep industry expertise:</b> with +10 years of experience, we understand the software development landscape, ensuring our professionals are not just technically skilled but industry-savvy.',
        '<b>Collaborative partnership:</b> open communication, teamwork, and shared objectives at the center.',
        "<b>Flexibility and agility:</b> we adapt swiftly to your changing needs, whether it's scaling up, pivoting, or exploring new technologies.",
        '<b>Quality assurance:</b> we uphold industry best practices and quality standards, with ISO 9001:2015 certification, ensuring customer satisfaction through quality products and services.',
      ],
    },
  },
];
export default servicesSliderTabs;
