import React from 'react';
import PropTypes from 'prop-types';

import { QuoteIcon } from 'ui/svg';
import Image from 'core/image';
import {
  Container,
  Content,
  Description,
  DescriptionContainer,
  TextContainer,
  Title,
  AuthorContainer,
  AuthorText,
  Quotes,
  ImgContainer,
  ContainerWithoutTitle,
} from './styles';

const Testimonial = ({
  image,
  alt,
  title,
  description,
  authorName,
  authorRol,
  imgAlign,
  textAlign,
  quoteColor,
  nameColor,
  isBlockQuote,
}) => {
  const QuoteDescription = () => (isBlockQuote ? description : <q>{description}</q>);
  return (
    <Container isBlockQuote={isBlockQuote}>
      <Content imgAlign={imgAlign} textAlign={textAlign}>
        {title ? (
          <>
            <TextContainer>
              <Title>{title}</Title>
              <Quotes>
                <QuoteIcon color={quoteColor} />
              </Quotes>
            </TextContainer>
            <DescriptionContainer>
              <Description isBlockQuote={isBlockQuote}>
                <QuoteDescription />
              </Description>
            </DescriptionContainer>
          </>
        ) : (
          <>
            <ContainerWithoutTitle>
              <Description isBlockQuote={isBlockQuote}>
                <QuoteDescription />
              </Description>
              <Quotes>
                <QuoteIcon color={quoteColor} />
              </Quotes>
            </ContainerWithoutTitle>
          </>
        )}
        <AuthorContainer>
          <ImgContainer>
            <Image
              alt={alt}
              fromContentful
              contentful_id={image}
              fluid
              width="100%"
              height="100%"
            />
          </ImgContainer>

          <AuthorText nameColor={nameColor}>
            <span>{authorName}</span>
            {authorRol}
          </AuthorText>
        </AuthorContainer>
      </Content>
    </Container>
  );
};

Testimonial.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.any.isRequired,
  authorName: PropTypes.string.isRequired,
  authorRol: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  actionBtn: PropTypes.bool,
  actionLink: PropTypes.string,
  imgAlign: PropTypes.oneOf(['start', 'center']),
  textAlign: PropTypes.oneOf(['start', 'center', 'end']),
  isBlockQuote: PropTypes.bool,
};

Testimonial.defaultProps = {
  imgAlign: 'start',
  textAlign: 'start',
  actionBtn: false,
  actionLink: '',
};

export default Testimonial;
