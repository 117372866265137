import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  left: 0 !important;
  display: flex;
  justify-self: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  height: ${({ theme }) => theme.newTheme.spacing(50)};
  background-color: ${({ theme }) => theme.newTheme.color.background.paleLightBlue};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    height: auto;
    padding: 2rem 0 1.5rem;
  }
`;

export const BadgesRowContainer = styled.div`
  display: flex;
  justify-self: center;
  justify-content: space-between;
  align-items: center;
  width: ${({ theme }) => theme.newTheme.spacing(240)};
  padding: 2rem 0 1.5rem;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 100%;
    gap: ${({ theme }) => theme.newTheme.spacing(15)};
    flex-wrap: wrap;
    padding: 2rem 0 3rem;
    justify-content: center;

    div:nth-child(4) {
      justify-content: flex-end;
    }

    div:nth-child(5) {
      flex-basis: 31%;
      width: 68%;
      padding-left: 3% !important;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    gap: ${({ theme }) => theme.newTheme.spacing(5)};
    flex-direction: column;
    margin-top: 0.1rem;
    div:nth-child(5) {
      width: 210px;
      margin: 0 auto;
      padding: 0 0 0 1% !important;
    }
  }
`;

export const BadgeBox = styled.div`
  display: flex;
  flex-basis: ${({ widthPercentage }) => widthPercentage};
  justify-content: center;
  align-items: center;
  padding: 0 ${({ theme }) => theme.newTheme.spacing(5)} 0
    ${({ theme }) => theme.newTheme.spacing(5)} !important;
  div {
    display: flex;
    justify-content: center;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    flex-basis: 26%;
    padding: 0;
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 50%;
  }
`;
