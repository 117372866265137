import React from 'react';
import PropTypes from 'prop-types';

const blockElements = ['embeddedYoutubeVideo'];

const WrapperContentfulElement = ({ children, node, className, ...rest }) => {
  const block = blockElements.includes(node?.data?.target?.sys?.contentType?.sys?.id);
  if (block) return <div>{children}</div>;
  return <p {...rest}>{children}</p>;
};

WrapperContentfulElement.propTypes = {
  className: PropTypes.string,
};

export default WrapperContentfulElement;
