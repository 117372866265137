import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: ${({ theme }) => theme.newTheme.spacing(30)} 0;
  padding: 0 ${({ theme }) => theme.spacing.medium};
  gap: ${({ theme }) => theme.newTheme.spacing(15)};
  align-items: center;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.newTheme.spacing(13)};
    margin: ${({ theme }) => theme.newTheme.spacing(20)} 0;
  }
`;

export const ImgContainer = styled.div`
  border-radius: 50%;
  height: 212px;
  width: 212px;
  background-color: ${({ theme }) => theme.newTheme.color.grey.light};
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  align-self: flex-start;
  margin: ${({ theme }) => theme.newTheme.spacing(6)} 0 0 0;

  div {
    height: 212px;
    width: 212px;
  }

  img {
    border-radius: 50%;
    transform: scale(1.5);
    height: 212px;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 70%;
    height: 70%;
    overflow: hidden;
    max-width: 180px;
    max-height: 180px;
    div {
      height: 180px;
      width: 180px;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 50%;
    height: 50%;
    overflow: hidden;
    max-width: 120px;
    max-height: 120px;
    div {
      height: 120px;
      width: 120px;
    }
  }
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  aling-items: flex-start;
  flex-basis: 75%;
  figcaption {
    padding: 0;
  }
`;

export const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

export const AuthorText = styled.figcaption`
  p {
    color: ${({ theme }) => theme.newTheme.color.pitchBlack};
    line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
    font-size: ${({ theme }) => theme.newTheme.font.text1.size};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    padding-bottom: ${({ theme }) => theme.newTheme.spacing(2)};

    ::first-letter {
      color: ${({ theme }) => theme.newTheme.color.pitchBlack} !important;
      line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight} !important;
      font-size: ${({ theme }) => theme.newTheme.font.text1.size} !important;
      font-weight: ${({ theme }) => theme.newTheme.font.weight.regular} !important;
    }
    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.text3.size};
      line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
    }
  }
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.newTheme.font.text3.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  color: ${({ theme }) => theme.newTheme.color.primary.main};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  text-transform: uppercase;
  letter-spacing: 2.72px;
  margin: 0;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  }
`;

export const Description = styled.p`
  p {
    ::first-letter {
      color: ${({ theme }) => theme.newTheme.color.pitchBlack}!important;
      font-size: ${({ theme }) => theme.newTheme.font.title5.size} !important;
      line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight} !important;
    }

    margin-bottom: 0 !important;
    color: ${({ theme }) => theme.newTheme.color.pitchBlack};
    font-size: ${({ theme }) => theme.newTheme.font.title5.size} !important;
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  }

  padding: ${({ theme }) => theme.newTheme.spacing(4)} 0;
  text-align: start;
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    p {
      font-size: ${({ theme }) => theme.newTheme.font.title6.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    }
  }
`;

export const ContainerCallToAction = styled.section`
  padding: ${({ theme }) => `${theme.newTheme.spacing(3)} 0`};
  display: flex;
  a {
    color: ${({ theme }) => theme.newTheme.color.white};
    text-decoration: none;
    border-bottom: none;
    button {
      box-shadow: ${({ theme }) => theme.newTheme.shadow.main};
      ${({ theme }) => `padding: ${theme.newTheme.spacing(3)} ${theme.newTheme.spacing(10)}`};
      display: flex;
      height: ${({ theme }) => theme.newTheme.spacing(11)};
      width: ${({ theme }) => theme.newTheme.spacing(47)};
      padding: ${({ theme }) => theme.newTheme.spacing(2)};
      gap: ${({ theme }) => theme.newTheme.spacing(2)};
      font-size: ${({ theme }) => theme.newTheme.font.text3.size};
      line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
    }
  }
`;
