import styled, { css } from 'styled-components';

const styledFirstLetter = (theme, firstLetter) =>
  ({
    colored: css`
      font-size: ${theme.newTheme.font.title2.size};
      line-height: ${theme.newTheme.font.title5.lineHeight};
      font-weight: ${theme.newTheme.font.weight.bold};
      color: ${props => props.color};
      padding-right: ${`${theme.newTheme.spacing(2)}`};
      padding-top: ${`${theme.newTheme.spacing(2)}`};
      float: left;
    `,
    normal: css`
      color: ${theme.newTheme.color.grey.main};
      font-size: ${theme.newTheme.font.text2.size};
      line-height: ${theme.newTheme.font.text2.lineHeight};
      font-weight: ${theme.newTheme.font.weight.regular};
    `,
  }[firstLetter]);

export const StyledWrapper = styled.div`
  width: 100%;
  h1,
  h2,
  h3 {
    color: ${({ theme }) => theme.newTheme.color.pitchBlack};
    padding: ${({ theme }) => `${theme.newTheme.spacing(6)} 0 ${theme.newTheme.spacing(6)} 0`};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
    margin: 0;

    b {
      color: ${props => props.color};
      padding: ${({ theme }) => `${theme.newTheme.spacing(13)} 0 ${theme.newTheme.spacing(6)} 0`};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
      margin: 0;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: ${({ theme }) => `${theme.newTheme.spacing(5)}`};
    li {
      p,
      p:first-of-type:first-letter {
        font-size: ${({ theme }) => theme.newTheme.font.text2.size};
        line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
        font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
        margin: 0;
        padding-top: ${({ theme }) => `${theme.newTheme.spacing(2)}`};
        padding-right: 0;
        color: ${({ theme }) => theme.newTheme.color.grey.main};
        float: none;
      }
    }
  }

  p:first-of-type:first-letter {
    ${({ theme, firstLetter }) => styledFirstLetter(theme, firstLetter)};
  }

  p {
    color: ${({ theme }) => theme.newTheme.color.grey.main};
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    margin-top: 0;

    b {
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
      color: ${({ theme }) => theme.newTheme.color.blogBold};
    }

    i {
      font-style: italic;
    }
  }

  a {
    color: ${({ theme }) => theme.newTheme.color.grey.main};
    border-bottom: 1px ${props => props.color} solid;
    u {
      text-decoration: none;
      color: ${({ theme }) => theme.newTheme.color.grey.main};
    }
  }
`;
export const StyledHeadersWrapper = styled.div`
  width: 100%;
  h2 {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
    scroll-margin-top: 1.6em;
  }
  h1,
  h2,
  h3 {
    color: ${({ theme }) => theme.newTheme.color.pitchBlack};
    padding: ${({ theme }) => `${theme.newTheme.spacing(13)} 0 ${theme.newTheme.spacing(6)} 0`};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
    margin: 0;
    b {
      color: ${({ theme }) => theme.newTheme.color.pitchBlack};
      padding: ${({ theme }) => `${theme.newTheme.spacing(13)} 0 ${theme.newTheme.spacing(6)} 0`};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
      margin: 0;
    }
  }

  p {
    color: ${({ theme }) => theme.newTheme.color.grey.main};
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    margin-top: 0;

    b {
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
      color: ${({ theme }) => theme.newTheme.color.blogBold};
    }

    i {
      color: ${({ theme }) => theme.newTheme.color.grey.main};
      font-style: italic;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: ${({ theme }) => `${theme.newTheme.spacing(5)}`};
    p {
      color: ${({ theme }) => theme.newTheme.color.grey.main};
      margin-bottom: 0.45em;
    }
    li::marker {
      color: ${({ theme }) => theme.newTheme.color.blogBold};
    }
  }

  ol {
    list-style-type: decimal;
    padding-left: ${({ theme }) => `${theme.newTheme.spacing(5)}`};
    p {
      color: ${({ theme }) => theme.newTheme.color.grey.main};
      margin-bottom: 0.45em;
    }
    li::marker {
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
      color: ${({ theme }) => theme.newTheme.color.blogBold};
    }
  }

  a {
    color: ${({ theme }) => theme.newTheme.color.grey.main};
    border-bottom: 2px ${props => props.color} solid;
    u {
      text-decoration: none;
      color: ${({ theme }) => theme.newTheme.color.grey.main};
    }
    span {
      text-decoration: underline solid ${({ theme }) => theme.colors.primary} 2px;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    h2 {
      scroll-margin-top: 3em;
    }
  }
`;

export const NewStyledWrapper = styled(StyledWrapper)`
  div {
    color: ${({ theme }) => theme.newTheme.color.grey.main};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  }

  h2 > strong,
  h3 > strong {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
  }
  p {
    margin: 0px;
  }

  h2,
  h3 {
    span {
      strong {
        font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
      }
    }
  }

  div > strong {
    color: ${({ theme }) => theme.newTheme.color.pitchBlack};
    margin: ${({ theme }) => `${theme.newTheme.spacing(13)} 0 ${theme.newTheme.spacing(6)} 0`};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
    font-size: 1.5em;
    display: block;
  }

  ul {
    margin-top: 0;
    li::marker {
      color: ${({ theme }) => theme.newTheme.color.blogBold};
    }

    li {
      color: ${({ theme }) => theme.newTheme.color.grey.main};
      margin-bottom: 0.45em;
      line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};

      p {
        padding: 0px;
      }

      span {
        color: ${({ theme }) => theme.newTheme.color.grey.main} !important;
        margin-bottom: 0.45em;
        line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
      }
    }
  }

  ol {
    margin-top: 0;
    list-style-type: decimal;
    padding-left: ${({ theme }) => `${theme.newTheme.spacing(5)}`};

    p {
      padding: 0px;
    }

    li {
      color: ${({ theme }) => theme.newTheme.color.grey.main};
      margin-bottom: 0.45em;
      line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
      span {
        color: ${({ theme }) => theme.newTheme.color.grey.main} !important;
        margin-bottom: 0.45em;
        line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
      }
    }
    li::marker {
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
      color: ${({ theme }) => theme.newTheme.color.blogBold};
    }
  }

  p {
    span {
      color: ${({ theme }) => theme.newTheme.color.grey.main} !important;
      font-size: ${({ theme }) => theme.newTheme.font.text2.size};
      line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
      margin: 0;

      b {
        font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
        color: ${({ theme }) => theme.newTheme.color.blogBold};
      }

      i {
        font-style: italic;
      }
    }
  }
`;
