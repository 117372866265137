import styled from 'styled-components';

const ImageWrapper = styled.div`
  flex-grow: ${props => (props.flex ? props.flex : '0')};
  display: ${props => (props.fromBubble ? 'flex' : '')};
  justify-content: ${props => (props.fromBubble ? 'center' : '')};
  width: ${props => (props.desktopWidth ? props.desktopWidth : props.defaultWidth)};
  .gatsby-image-wrapper {
    width: ${props => (props.desktopWidth ? props.desktopWidth : props.defaultWidth)};
    height: ${props => (props.desktopHeight ? props.desktopHeight : props.defaultWidth)};
    div {
      padding: ${props => (props.padding ? `${props.padding} !important;` : '')};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    width: ${props =>
      props.tabletLandscapeWidth ? props.tabletLandscapeWidth : props.defaultWidth};
    .gatsby-image-wrapper {
      width: ${props =>
        props.tabletLandscapeWidth ? props.tabletLandscapeWidth : props.defaultWidth};
      height: ${props =>
        props.tabletLandscapeHeight ? props.tabletLandscapeHeight : props.defaultWidth};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    width: ${props => (props.tabletWidth ? props.tabletWidth : props.defaultWidth)};
    .gatsby-image-wrapper {
      width: ${props => (props.tabletWidth ? props.tabletWidth : props.defaultWidth)};
      height: ${props => (props.tabletHeight ? props.tabletHeight : props.defaultHeight)};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    width: ${props => (props.mobileWidth ? props.mobileWidth : props.defaultWidth)};
    height: ${props => (props.mobileHeight ? props.mobileHeight : props.defaultHeight)};
    .gatsby-image-wrapper {
      width: ${props => (props.mobileWidth ? props.mobileWidth : props.defaultWidth)};
      height: ${props => (props.mobileHeight ? props.mobileHeight : props.defaultHeight)};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.smallMobile}) {
    width: ${props => (props.smallMobileWidth ? props.smallMobileWidth : props.defaultWidth)};
    height: ${props => (props.smallMobileHeight ? props.smallMobileHeight : props.defaultHeight)};
    .gatsby-image-wrapper {
      width: ${props => (props.smallMobileWidth ? props.smallMobileWidth : props.defaultWidth)};
      height: ${props => (props.smallMobileHeight ? props.smallMobileHeight : props.defaultHeight)};
    }
  }

  * {
    height: ${props => (props.height ? props.height : '100%')};
    min-height: ${props => (props.minHeight ? props.minHeight : '100%')};
  }
`;

export default ImageWrapper;
