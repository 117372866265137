import styled from 'styled-components';

export const CardStyled = styled.div`
  background: ${({ theme }) => theme.newTheme.color.white};
  display: flex;
  margin: ${({ theme }) => theme.newTheme.spacing(5)} 0;
  box-shadow: ${({ theme }) => theme.newTheme.shadow.main};
  border-radius: ${({ theme }) => theme.newTheme.border.radius.medium};
  overflow: hidden;
  width: 100%;
  background: ${({ theme }) => theme.newTheme.color.white};
  height: ${props => props.height};
  p {
    margin-bottom: 0;
  }

  &:hover {
    h5 {
      text-decoration: underline;
    }

    @keyframes zoom {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.05);
      }
    }

    img {
      animation: zoom 0.3s ease-in-out 0s 1;
      transform: scale(1.05);
    }

    svg {
      transition: ${({ theme }) => theme.newTheme.transition.main};
      transform: translateY(10px);
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(6)};
    height: inherit;
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.newTheme.spacing(5)} ${theme.newTheme.spacing(6)}`};
  width: 120%;
  justify-content: space-between;
  max-width: ${props => props.maxWidthText};
  height: 100%;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
    min-width: 100%;
    flex-grow: 1;
  }
`;

export const TopContainerStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  box-shadow: none;
  h4 {
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.newTheme.color.grey.light};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    font-size: ${({ theme }) => theme.newTheme.font.text3.size};
    line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
  }

  div {
    color: ${({ theme }) => theme.newTheme.color.white};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    font-size: ${({ theme }) => theme.newTheme.font.text3.size};
    line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
  }
`;

export const TitleContainer = styled.div`
  margin: 0;
`;

export const TitleStyled = styled.h3`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.newTheme.color.grey.main} !important;
  font-weight: ${({ theme }) => theme.newTheme.font.weight.medium} !important;
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight} !important;
  margin: ${({ theme }) => `${theme.newTheme.spacing(3)} 0 0 0`};
  width: 100%;
  padding: ${({ theme }) => `${theme.newTheme.spacing(3)} 0 !important`};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    margin: ${({ theme }) => theme.newTheme.spacing(5)} 0;
  }
`;

export const CallToAction = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.newTheme.spacing(4)};
  p {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    font-size: ${({ theme }) => theme.newTheme.font.text3.size};
    line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
    color: ${({ theme }) => theme.newTheme.color.grey.light};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      margin: 0;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    gap: ${({ theme }) => theme.newTheme.spacing(2)};
  }
`;

export const IconContainer = styled.div`
  transform: rotate(-90deg);

  svg {
    transition: ${({ theme }) => theme.newTheme.transition.main};
    height: ${({ theme }) => theme.newTheme.spacing(5)};
  }
`;
