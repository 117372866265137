import React from 'react';
import PropTypes from 'prop-types';
import AutoSlider from 'atomic-design/molecules/auto-slider';
import PageSection from '../page-section';

const LandingPageCarousel = ({ clients }) => {
  return (
    <PageSection containerSize="fluid" options="fullWidth">
      <AutoSlider clients={clients} />
    </PageSection>
  );
};

LandingPageCarousel.propTypes = {
  clients: PropTypes.object.isRequired,
};

export default LandingPageCarousel;
