import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import WrapperContentfulElement from 'atomic-design/atoms/WrapperContentfulElement';
import CodeParser from 'atomic-design/atoms/code-parser';
import { newHandleQuote, injectId } from 'common/string-helpers';
import {
  handleEmbeddedEntry,
  handleImageNode,
  handleTableNode,
  isInternalUrl,
} from 'common/helpers';

const options = {
  renderNode: {
    blockquote: node => <WrapperContentfulElement>{newHandleQuote(node)}</WrapperContentfulElement>,
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <WrapperContentfulElement node={node}>{handleImageNode(node)}</WrapperContentfulElement>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => (
      <WrapperContentfulElement node={node}>{handleEmbeddedEntry(node)}</WrapperContentfulElement>
    ),
    [INLINES.EMBEDDED_ENTRY]: node => (
      <WrapperContentfulElement node={node}>{handleEmbeddedEntry(node)}</WrapperContentfulElement>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} target={isInternalUrl(node.data.uri) ? '' : '_blank'}>
        {children}
      </a>
    ),
    'heading-1': node => injectId(node),
    'heading-2': node => injectId(node),
    'heading-3': node => injectId(node),
    'heading-4': node => injectId(node),
    'heading-5': node => injectId(node),
    'heading-6': node => injectId(node),
    table: node => handleTableNode(node),
  },
  renderMark: {
    code: text => <CodeParser text={text[1]} />,
  },
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br />, textSegment];
    }, []);
  },
};

export default options;
