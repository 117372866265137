import React from 'react';
import PropTypes from 'prop-types';

import Chip from 'atomic-design/atoms/chip';
import { formatDate } from 'common/helpers';
import { trimEllipsis } from 'common/string-helpers';
import Image from 'core/image';
import { ChevronArrow } from 'ui/svg';
import theme from 'styles/theme';
import Link from 'atomic-design/atoms/link';

import {
  CardStyled,
  TopContainerStyled,
  TitleStyled,
  InformationContainer,
  TitleContainer,
  CallToAction,
  IconContainer,
} from './styles';

const EntryCardSmall = ({
  height,
  maxWidthText,
  categoryColor,
  categoryName,
  from,
  hero,
  publishedDate,
  title,
  slug,
}) => {
  const toPage = from === 'caseStudy' ? 'work' : from === 'blogPost' ? 'blog' : null;
  const to = `/${toPage}/${slug}`;
  return (
    <Link key={to} to={to} rel={slug}>
      <CardStyled height={height}>
        <Image
          alt={title}
          contentful_id={hero}
          fromContentful
          padding="0"
          width="100%"
          tabletLandscapeWidth="inherit"
          mobileHeight="100px"
          smallMobileWidth="inherit"
          smallMobileHeight="100px"
          height="inherit"
        />
        <InformationContainer maxWidthText={maxWidthText}>
          <TopContainerStyled>
            <Chip color={categoryColor || '#304BE2'}>{categoryName || 'Our Work'}</Chip>
            <h4>{formatDate(publishedDate)}</h4>
          </TopContainerStyled>
          <TitleContainer>
            <TitleStyled color={categoryColor || '#304BE2'}>{trimEllipsis(title, 58)}</TitleStyled>
          </TitleContainer>

          <CallToAction>
            <p>Read the complete article</p>
            <IconContainer>
              <ChevronArrow color={theme.newTheme.color.grey.light} />
            </IconContainer>
          </CallToAction>
        </InformationContainer>
      </CardStyled>
    </Link>
  );
};

EntryCardSmall.propTypes = {
  categoryColor: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  hero: PropTypes.string.isRequired,
  publishedDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default EntryCardSmall;
