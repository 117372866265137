import React from 'react';
import PropTypes from 'prop-types';

import Accordion from 'atomic-design/molecules/accordion';
import { CtaContainer } from './styles';
import BasicCallToAction from 'atomic-design/molecules/call-to-action/basic-call-to-action';

const ServicesAccordeonList = ({ servicesList, redirectFunction }) => {
  const newList = servicesList.map(item => ({
    title: item.tabName,
    description: item.content.description,
    children: [
      <CtaContainer>
        <BasicCallToAction
          onclick={redirectFunction}
          buttonTitle={item.content.cta.ctaButtonLabel}
        />
      </CtaContainer>,
    ],
  }));
  return <Accordion list={newList} />;
};

ServicesAccordeonList.propTypes = {
  servicesList: PropTypes.array.isRequired,
};

export default ServicesAccordeonList;
