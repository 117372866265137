import React, { useState } from 'react';
import { Tabs, Tab, Content, Container, BulletList } from './styles';
import { Title } from 'atomic-design/molecules/clutch-review/styles';
import { GradientTitle } from '../article-and-image/styles';
import servicesSliderTabs from 'domain/services-slider-tabs';
import useIsMobile from 'hooks/useIsMobile';
import ServicesAccordeonList from '../services-list';
import useScrollToForm from 'hooks/UseScrollToForm';
import BasicCallToAction from 'atomic-design/molecules/call-to-action/basic-call-to-action';

const ServicesSlider = () => {
  const [active, setActive] = useState(0);
  const [slideContent, setSlideContent] = useState(servicesSliderTabs[0].content);
  const isMobile = useIsMobile('mobile');

  const handleClick = index => {
    if (index !== active) {
      setActive(index);
      setSlideContent(servicesSliderTabs[index].content);
    }
  };

  // TO-DO: We need to investigate if there is a more data-driven way to find the component name.
  const scrollToContactForm = useScrollToForm('form[name="contact"]');

  return (
    <Container>
      <Title>What we can do for you</Title>
      {isMobile ? (
        <ServicesAccordeonList
          servicesList={servicesSliderTabs}
          redirectFunction={scrollToContactForm}
        />
      ) : (
        <>
          <Tabs>
            {servicesSliderTabs.map(tab => (
              <Tab
                onClick={() => handleClick(servicesSliderTabs.indexOf(tab))}
                active={active === servicesSliderTabs.indexOf(tab)}
                id={servicesSliderTabs.indexOf(tab)}
                key={tab.tabName}
              >
                {tab.tabName}
              </Tab>
            ))}
          </Tabs>
          <Content>
            <div>
              <GradientTitle>{slideContent.title}</GradientTitle>
              <BasicCallToAction
                onclick={() => scrollToContactForm()}
                buttonTitle={slideContent.cta.ctaButtonLabel}
              />
            </div>
            <div>
              {slideContent.description &&
                slideContent.description.length &&
                slideContent.description.map(paragraph => (
                  <p dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}

              <BulletList>
                <ul>
                  {slideContent.bulletList &&
                    slideContent.bulletList.map(item => (
                      <li key={item} dangerouslySetInnerHTML={{ __html: item }}></li>
                    ))}
                </ul>
              </BulletList>
            </div>
          </Content>
        </>
      )}
    </Container>
  );
};
ServicesSlider.propTypes = {};

ServicesSlider.defaultProps = {};

export default ServicesSlider;
