import styled from 'styled-components';

export const Container = styled.ul`
  padding: 0;
  margin: 0;
`;

export const CtaContainer = styled.div`
  width: 100%;
  section {
    display: flex;
    align-items: flex-start;
    a {
      border-bottom: none;
    }
  }
`;
