import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { findFluidImage, findContentfulAsset } from 'common/helpers';
import ImageWrapper from './styles';

const Image = props => {
  const {
    partialPathName,
    fromContentful,
    contentful_id,
    fixed,
    width,
    height,
    alt,
    size,
    minHeight,
    maxWidth,
    maxHeight,
    padding,
    margin,
    transform,
    borderRadius,
    smallMobileWidth,
    smallMobileHeight,
    mobileWidth,
    mobileHeight,
    tabletWidth,
    tabletHeight,
    tabletLandscapeWidth,
    desktopWidth,
    flex,
    desktopHeight,
    fromBubble,
  } = props;

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 3200, quality: 100, base64Width: 20) {
              aspectRatio
              base64
              src
              srcSet
              sizes
            }
          }
        }
      }
      allContentfulAsset {
        edges {
          node {
            contentful_id
            fluid(maxWidth: 3200, quality: 100) {
              aspectRatio
              src
              srcSet
              sizes
            }
            medium: fixed(width: 500) {
              width
              height
              src
              srcSet
            }
            big: fixed(width: 1920) {
              width
              height
              src
              srcSet
            }
            placeholder: fixed(width: 50) {
              base64
            }
          }
        }
      }
    }
  `);

  const setSize = (imageData, size) => {
    const selectedSize = imageData.node[size] || imageData.node.big;

    const image = {
      ...selectedSize,
      base64: imageData.node.placeholder.base64,
    };

    return image;
  };

  const imageData = fromContentful
    ? findContentfulAsset(data.allContentfulAsset.edges, contentful_id, props, data)
    : findFluidImage(data, partialPathName);

  return (
    <ImageWrapper
      defaultWidth={width}
      smallMobileWidth={smallMobileWidth}
      smallMobileHeight={smallMobileHeight}
      mobileWidth={mobileWidth}
      mobileHeight={mobileHeight}
      tabletWidth={tabletWidth}
      tabletHeight={tabletHeight}
      tabletLandscapeWidth={tabletLandscapeWidth}
      desktopWidth={desktopWidth}
      desktopHeight={desktopHeight}
      height={height}
      minHeight={minHeight}
      borderRadius={borderRadius}
      padding={padding}
      flex={flex}
      fromBubble={fromBubble}
    >
      <Img
        alt={alt}
        fluid={fixed ? undefined : imageData.node.fluid}
        fixed={fixed ? setSize(imageData, size) : undefined}
        style={{
          width,
          height,
          maxHeight,
          maxWidth,
          padding,
          margin,
          transform,
          borderRadius,
        }}
      />
    </ImageWrapper>
  );
};

Image.propTypes = {
  partialPathName: PropTypes.string,
  fromContentful: PropTypes.bool,
  contentful_id: PropTypes.string,
  fixed: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  transform: PropTypes.string,
  borderRadius: PropTypes.string,
  minHeight: PropTypes.string,
  smallMobileWidth: PropTypes.string,
  mobileWidth: PropTypes.string,
  tabletWidth: PropTypes.string,
  tabletLandscapeWidth: PropTypes.string,
  desktopWidth: PropTypes.string,
};

Image.defaultProps = {
  partialPathName: '',
  fromContentful: false,
  contentful_id: '',
  fixed: false,
  width: '',
  height: '',
  alt: '',
  size: 'big',
  maxHeight: '',
  maxWidth: '',
  padding: '',
  margin: '',
  transform: '',
  borderRadius: '',
  minHeight: '',
  smallMobileWidth: '',
  mobileWidth: '',
  tabletWidth: '',
  tabletLandscapeWidth: '',
  desktopWidth: '',
};
export default Image;
