import React from 'react';
import PropTypes from 'prop-types';

import PageSection from 'atomic-design/atoms/page-section';
import { Background, Text, Container } from './styles';

const Overrun = ({
  background,
  padding,
  children,
  margin,
  mobilePadding = padding,
  mobileMargin = margin,
  fullWidth,
  size,
}) => {
  return (
    <Container margin={margin} mobileMargin={mobileMargin} fullWidth={fullWidth}>
      <Background />
      <PageSection
        padding={padding}
        background={background}
        mobilePadding={mobilePadding}
        containerSize={size}
      >
        <Text>{children}</Text>
      </PageSection>
    </Container>
  );
};

Overrun.propTypes = {
  background: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.string.isRequired,
  margin: PropTypes.string,
  mobilePadding: PropTypes.string,
  mobileMargin: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'fluid']),
  fullWidth: PropTypes.bool,
};

Overrun.defaultProps = {
  background: '',
  padding: '0',
  margin: '0',
  fullWidth: true
};

export default Overrun;
