import React from 'react';
import PropTypes from 'prop-types';

import Image from 'core/image';
import { SliderContainer, Slider, SlideTrack, SlideBox } from './styles';

const AutoSlider = ({ clients }) => {
  let clientsNumber = clients.length;
  return (
    <SliderContainer slidesNumber={clientsNumber}>
      <Slider>
        <SlideTrack slidesNumber={clientsNumber}>
          {clients.map(edge => (
            <SlideBox key={edge.node.logo.title}>
              <Image
                fromContentful
                contentful_id={edge.node.logo.contentful_id}
                alt={edge.node.logo.title}
                width="100%"
                fluid
              />
            </SlideBox>
          ))}
          {clients.map(edge => (
            <SlideBox>
              <Image
                fromContentful
                contentful_id={edge.node.logo.contentful_id}
                alt={edge.node.logo.title}
                width="100%"
                fluid
              />
            </SlideBox>
          ))}
        </SlideTrack>
      </Slider>
    </SliderContainer>
  );
};

AutoSlider.propTypes = {
  href: PropTypes.string,
  key: PropTypes.string,
  rel: PropTypes.string,
  contentfulId: PropTypes.string,
  alt: PropTypes.string,
};

AutoSlider.defaultProps = {
  href: '',
  key: '',
  rel: '',
  contentfulId: '',
  alt: '',
};

export default AutoSlider;
