import React from 'react';
import PropTypes from 'prop-types';

import Image from 'core/image';
import Button from 'atomic-design/atoms/button';
import Link from 'atomic-design/atoms/link';
import {
  Container,
  Description,
  Title,
  AuthorText,
  ImgContainer,
  ContainerCallToAction,
  TextsContainer,
} from './styles';
import { PlayArrow } from 'ui/svg';

const TestimonialWithCTA = ({ image, alt, title, quote, authorName, authorRol, cta }) => {
  const ctaHref = cta.fields.href['en-US'];
  const ctaTarget = cta.fields.target['en-US'];
  const ctaText = cta.fields.text['en-US'];

  return (
    <Container>
      <ImgContainer>
        <Image alt={alt} fromContentful contentful_id={image} fluid width="100%" height="100%" />
      </ImgContainer>
      <TextsContainer>
        <Title>{title}</Title>
        <Description>
          <p>{quote}</p>
        </Description>
        <AuthorText>
          <p>
            {authorName}, {authorRol}
          </p>
        </AuthorText>
        <ContainerCallToAction>
          <Link target={ctaTarget} href={ctaHref} rel="noopener noreferrer">
            <Button aria-label="link to video">
              <PlayArrow color={'#fff'} />
              {ctaText}
            </Button>
          </Link>
        </ContainerCallToAction>
      </TextsContainer>
    </Container>
  );
};

TestimonialWithCTA.propTypes = {
  title: PropTypes.string.isRequired,
  quote: PropTypes.any.isRequired,
  cta: PropTypes.any.isRequired,
  authorName: PropTypes.string.isRequired,
  authorRol: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  actionBtn: PropTypes.bool,
  actionLink: PropTypes.string,
  imgAlign: PropTypes.oneOf(['start', 'center']),
  textAlign: PropTypes.oneOf(['start', 'center', 'end']),
  isBlockQuote: PropTypes.bool,
};

TestimonialWithCTA.defaultProps = {
  imgAlign: 'start',
  textAlign: 'start',
  actionBtn: false,
  actionLink: '',
};

export default TestimonialWithCTA;
