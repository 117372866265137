import React from 'react';
import PropTypes from 'prop-types';

import Button from 'atomic-design/atoms/button';
import { ContainerCallToAction } from './styles';

const BasicCallToAction = ({ buttonTitle, onclick, ...rest }) => {
  return (
    <ContainerCallToAction>
      <Button aria-label="link to page" onClick={() => onclick()} {...rest}>
        {buttonTitle}
      </Button>
    </ContainerCallToAction>
  );
};

BasicCallToAction.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
};

export default BasicCallToAction;
