import styled from 'styled-components';

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  align-items: center;
  display: flex;
  height: 20vh;

  img {
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%) opacity(50%);
    filter: gray; /* IE 6-9 */

    transition: ${({ theme }) => theme.newTheme.transition.main} !important;

    &:hover {
      transition: ${({ theme }) => theme.newTheme.transition.main} !important;
      -webkit-filter: none;
      -moz-filter: none;
      -ms-filter: none;
      filter: none;
      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
        filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        filter: grayscale(100%) opacity(50%);
        filter: gray; /* IE 6-9 */
      }
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    justify-content: space-around;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.smallMobile}) {
    justify-content: center;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * ${props => props.slidesNumber}));
    }
  }
`;

export const Slider = styled.div`
  background: white;
  height: ${({ theme }) => theme.newTheme.spacing(30)};
  overflow: hidden;
  position: relative;
  width: 100%;

  &:before {
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
    height: ${({ theme }) => theme.newTheme.spacing(30)};
    position: absolute;
    width: ${({ theme }) => theme.newTheme.spacing(30)};
    z-index: 2;
    left: 0;
    top: 0;
  }

  &:after {
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
    height: ${({ theme }) => theme.newTheme.spacing(30)};
    position: absolute;
    width: ${({ theme }) => theme.newTheme.spacing(30)};
    z-index: 2;
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    &:before {
      width: ${({ theme }) => theme.newTheme.spacing(10)};
    }

    &:after {
      width: ${({ theme }) => theme.newTheme.spacing(10)};
    }
  }
`;

export const SlideTrack = styled.div`
  animation: scroll 20s linear infinite;
  display: flex;
  width: calc(${({ theme }) => theme.newTheme.spacing(75)} * ${props => props.slidesNumber} * 2);
`;

export const SlideBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.newTheme.spacing(5)} 0
    ${({ theme }) => theme.newTheme.spacing(5)} !important;
  height: ${({ theme }) => theme.newTheme.spacing(30)};
  width: ${({ theme }) => theme.newTheme.spacing(75)};
`;
