import React from 'react';
import PropTypes from 'prop-types';

import ChipStyled from './styles';

const Chip = ({ color, hoverColor, children }) => (
  <ChipStyled hoverColor={hoverColor} color={color}>
    {children}
  </ChipStyled>
);

Chip.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hoverColor: PropTypes.string,
};

Chip.defaultProps = {
  hoverColor: '',
};

export default Chip;
