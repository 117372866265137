import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Image from 'core/image';
import { Container, BadgeBox, BadgesRowContainer } from './styles';
import PageSection from 'atomic-design/atoms/page-section';

const BadgesAwsContainer = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://widget.clutch.co/static/js/widget.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.CLUTCHCO.Init();
    };

    return () => {
      window.CLUTCHCO.Destroy();
      document.body.removeChild(script);
    };
  }, []);
  return (
    <PageSection containerSize="fluid" options="fullWidth">
      <Container>
        <BadgesRowContainer>
          <BadgeBox key="badge-aws" widthPercentage={'20%'}>
            <Image partialPathName="clutch-badge-aws-partner" alt="badge-aws" width="88%" />
          </BadgeBox>
          <BadgeBox key="aws-sales" widthPercentage={'20%'}>
            <Image partialPathName="aws-sales-badge" alt="aws-sales" width="95%" />
          </BadgeBox>
          <BadgeBox key="aws-technical" widthPercentage={'20%'}>
            <Image partialPathName="aws-technical-badge" alt="aws-technical" width="95%" />
          </BadgeBox>
        </BadgesRowContainer>
      </Container>
    </PageSection>
  );
};

BadgesAwsContainer.propTypes = {
  href: PropTypes.string,
  key: PropTypes.string,
  rel: PropTypes.string,
  contentfulId: PropTypes.string,
  alt: PropTypes.string,
};

BadgesAwsContainer.defaultProps = {
  href: '',
  key: '',
  rel: '',
  contentfulId: '',
  alt: '',
};

export default BadgesAwsContainer;
