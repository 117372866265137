import styled from 'styled-components';

export const Container = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`;
export const Video = styled.iframe`
  width: 100%;
  border: none;
  height: ${({ theme }) => theme.newTheme.spacing(75)};
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    height: ${({ theme }) => theme.newTheme.spacing(55)};
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.newTheme.spacing(5)};
  padding: ${({ theme }) => theme.newTheme.spacing(5)};
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0;
    padding-top: ${({ theme }) => theme.newTheme.spacing(5)};
  }
`;
export const QuoteContainer = styled.div`
  width: 50px;
  height: 40px;
  transform: translateY(-8px);
`;

export const Information = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.light};
  color: ${({ theme }) => theme.newTheme.color.black};
  font-style: italic;
`;
