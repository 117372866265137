import { useStaticQuery, graphql } from 'gatsby';

export const useTeamData = () => {
  const data = useStaticQuery(graphql`
    query TeamDataQuery {
      allContentfulTeamMemberAboutUs(sort: { fields: order, order: ASC }) {
        edges {
          node {
            name
            linkedinUrl
            role
            description
            skills
            image {
              contentful_id
            }
            order
            pageToRender
          }
        }
      }
    }
  `);
  return data.allContentfulTeamMemberAboutUs.edges;
};
