import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-self: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
    div {
      margin: 0 auto;
      padding: 0 0 ${({ theme }) => theme.newTheme.spacing(4)};
      width: ${({ theme }) => theme.newTheme.spacing(230)};

      @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
        width:100%;
      }
      @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
        width:100%;
      }
    }
  }
 
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  justify-self: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  gap: 2rem;
  section {
    padding: 0;
    height: ${({ theme }) => theme.newTheme.spacing(12)};
    width: ${({ theme }) => theme.newTheme.spacing(50)};
    justify-content: flex-start;
    a {
      border: none;
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    section {
      width: ${({ theme }) => theme.newTheme.spacing(45)};
      min-width: 190px;
    }
  }
  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    section {
      width: ${({ theme }) => theme.newTheme.spacing(50)};
      min-width: 190px;
      align-items: flex-start;
    }
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
  }
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  line-height: ${({ theme }) => `${theme.newTheme.font.title3.lineHeight}`};
  text-align: center;
  margin: ${({ theme }) => theme.newTheme.spacing(5)} 0 ${({ theme }) => theme.newTheme.spacing(10)};
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin: ${({ theme }) => theme.newTheme.spacing(3)} 0
      ${({ theme }) => theme.newTheme.spacing(5)};
  }
`;

export const CTASubtitle = styled.h5`
  display: flex;
  align-self: center;
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  line-height: ${({ theme }) => `${theme.newTheme.font.text2.lineHeight}`};
  color: ${({ theme }) => theme.newTheme.color.pitchBlack};
  margin: 0;
`;
