const useScrollToForm = componentName => {
  const scrollToForm = () => {
    // The component is searched in the DOM and not in a ref, because the passage of the ref by props is more complex.
    // componentName should receive the name of the component as it is in the DOM. Example: 'form[name="contact"]'
    const form = document.querySelector(`${componentName}`);
    if (form) {
      form.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return scrollToForm;
};

export default useScrollToForm;
