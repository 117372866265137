import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, CTAContainer, CTASubtitle, Title } from './styles';

import BasicCallToAction from '../call-to-action/basic-call-to-action';
import useScrollToForm from 'hooks/UseScrollToForm';

const ClutchReview = ({ component }) => {
  const scrollToContactForm = useScrollToForm('form[name="contact"]');
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://widget.clutch.co/static/js/widget.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.CLUTCHCO.Init();
    };

    return () => {
      window.CLUTCHCO.Destroy();
      document.body.removeChild(script);
    };
  }, []);
  return (
    <Container>
      <Title>Verified Clutch Reviews</Title>
      <div dangerouslySetInnerHTML={{ __html: component }} />
      <CTAContainer>
        <CTASubtitle>{"Think we might be a fit? Let's build and grow together."}</CTASubtitle>
        <BasicCallToAction buttonTitle={'Get in touch'} onclick={scrollToContactForm} />
      </CTAContainer>
    </Container>
  );
};

ClutchReview.propTypes = {
  component: PropTypes.string.isRequired,
};

ClutchReview.defaultProps = {};

export default ClutchReview;
