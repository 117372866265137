import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: ${({ theme }) => theme.newTheme.spacing(10)} 0;
`;

export const BoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  gap: 2rem;
  flex-direction: row;
  max-width: ${({ theme }) => theme.newTheme.spacing(235)};
  margin: ${({ theme }) => theme.newTheme.spacing(15)} 0 0;
`;

export const ImgAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flrx-start;
  gap: ${({ theme }) => theme.newTheme.spacing(4)};
  flex-basis: 27%;
  min-width: ${({ theme }) => theme.newTheme.spacing(45)};
  h4 {
    color: ${({ theme }) => theme.newTheme.color.pitchBlack};
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    flex-direction: row;
    align-items: flrx-start;
    gap: ${({ theme }) => theme.newTheme.spacing(3)};
    min-width: auto;
    h4 {
      text-align: left;
      width: ${({ theme }) => theme.newTheme.spacing(40)};
    }
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  width: ${({ theme }) => theme.newTheme.spacing(30)};
  overflow: hidden;
  gap: 2rem;
  div {
    flex-basis: 50%;
  }
`;

export const GradientTitle = styled.h2`
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  line-height: ${({ theme }) => theme.newTheme.font.title2.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold} !important;
  padding: ${({ theme }) => theme.newTheme.spacing(10)} 0
    ${({ theme }) => theme.newTheme.spacing(10)} !important;
  margin: 0 !important;
  background: ${({ theme }) => theme.newTheme.color.gradient.background.primary.main};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 100%;
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    padding: ${({ theme }) => theme.newTheme.spacing(5)} 0
      ${({ theme }) => theme.newTheme.spacing(2)} !important;
  }
`;

export const PreTitle = styled.h4`
  font-size: ${({ theme }) => theme.newTheme.font.text3.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  color: ${({ theme }) => theme.newTheme.color.primary.main};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  text-transform: uppercase;
  letter-spacing: 2.72px;
  margin: 0;
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  }
`;
