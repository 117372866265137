import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: ${({ theme }) => theme.newTheme.spacing(15)} 0 0 0;
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
  }
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: ${({ theme }) => theme.newTheme.spacing(20)};
  gap: ${({ theme }) => theme.newTheme.spacing(15)};
  transition: left 0.25s;
  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: flex-start;
    margin: ${({ theme }) => theme.newTheme.spacing(5)} 0
      ${({ theme }) => theme.newTheme.spacing(2)} 0;
    gap: ${({ theme }) => theme.newTheme.spacing(5)};
    width: 100%;
  }
`;

export const Tab = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  margin-right: 0.1em;
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${props =>
    props.active
      ? ({ theme }) => theme.newTheme.color.amethystViolet.main
      : ({ theme }) => theme.newTheme.color.pitchBlack};
  border-bottom: 2px solid
    ${props => (props.active ? ({ theme }) => theme.newTheme.color.amethystViolet.main : 'none')};
  transition: border-bottom 0.2s;
  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.newTheme.spacing(3)} 0
      ${({ theme }) => theme.newTheme.spacing(6)};
    align-self: flex-end;
    white-space: nowrap;
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text3.size};
  }
`;

export const Content = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.newTheme.spacing(15)} 0;
  gap: 0;
  div:nth-child(1) {
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
    gap: ${({ theme }) => theme.newTheme.spacing(15)};
    h2 {
      width: 90%;
      padding: 0 !important;
    }
    section {
      padding: 0;
      justify-content: flex-start;
      align-items: flex-start;
      width: 85%;

      h4 {
        text-align: left;
        font-size: ${({ theme }) => theme.newTheme.font.text1.size};
        line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
        font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
      }
      a {
        border: none;
        font-size: ${({ theme }) => theme.newTheme.font.text3.size};
        line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
      }
    }
  }
  div:nth-child(2) {
    flex-basis: 55%;
    p {
      margin: 0 0 ${({ theme }) => theme.newTheme.spacing(2)};
      font-size: ${({ theme }) => theme.newTheme.font.text1.size};
      line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
    }
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    height: fit-content;
    gap: ${({ theme }) => theme.newTheme.spacing(15)};

    div:nth-child(1) {
      flex-basis: 100%;
      gap: ${({ theme }) => theme.newTheme.spacing(10)};
      h2 {
        width: 100%;
      }
      section {
        width: 95%;
      }
    }
    div:nth-child(2) {
      flex-basis: 100%;
    }
  }
`;

export const BulletList = styled.div`
  margin: ${({ theme }) => theme.newTheme.spacing(17)} 0 0 0;
  li {
    font-size: ${({ theme }) => theme.newTheme.font.text1.size};
    line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
  }
  }
`;
