export const parseCarouselClients = clients =>
  clients.map(client => ({
    node: {
      companyName: client.fields.companyName['en-US'],
      logo: {
        contentful_id: client.fields.logo['en-US'].sys.contentful_id,
        title: client.fields.logo['en-US'].fields.title['en-US'],
      },
      order: client.fields.order['en-US'],
      website: client.fields.website['en-US'],
    },
  }));

export const parseFaqsList = list =>
  list.map(item => ({
    description: {
      json: item.answer.json,
    },
    title: item.question,
  }));

export const parseTable = table =>  {
  if (
    table.content &&
    table.content[0] &&
    table.content[0].content &&
    table.content[0].content[0] &&
    table.content[0].content[0].nodeType &&
    table.content[1] &&
    table.content[1].content &&
    table.content[1].content[0] &&
    table.content[1].content[0].nodeType &&
    table.content[1].content[0].nodeType === 'table-cell'
  ) {
    const tableHead = table.content[0].content;
    const tableBody = table.content.slice(1);

    const headers = tableHead.map(header => header.content[0].content[0].value);
    const rows = tableBody.map(row =>
      row.content.map(cell => cell.content[0].content[0].value)
    );

    return { headers, rows };
  }
  return null;
};

export default { parseCarouselClients, parseFaqsList, parseTable };
