import React from 'react';
import PropTypes from 'prop-types';

import ListDivider from 'atomic-design/atoms/list-divider';
import SocialNetworkList from 'atomic-design/atoms/social-network-list';
import Image from 'core/image';
import {
  Container,
  ImgContainer,
  InformationContainer,
  SkillText,
  RoleText,
  DescriptionText,
  NetworkContainer,
  ImgMainContainer,
} from './styles';

const TeamItem = ({ image, title, role, description, skillList, socialList, leftImage }) => {
  return (
    <Container leftImage={leftImage}>
      <ImgMainContainer>
        <ImgContainer>
          <Image
            alt={`${title} image`}
            fromContentful
            contentful_id={image}
            fluid
            width="100%"
            height="100%"
          />
        </ImgContainer>
      </ImgMainContainer>
      <InformationContainer>
        <h4>{title}</h4>
        <RoleText>{role}</RoleText>
        <DescriptionText>{description}</DescriptionText>
        {skillList && skillList.length > 0 && (
          <ul>
            {skillList.map(skillElement => (
              <li key={skillElement.id}>
                <SkillText>{skillElement}</SkillText>
                <ListDivider />
              </li>
            ))}
          </ul>
        )}
        {socialList && socialList.length > 0 && !socialList.some(icon => icon.to === null) && (
          <NetworkContainer>
            <SocialNetworkList iconList={socialList} />
          </NetworkContainer>
        )}
      </InformationContainer>
    </Container>
  );
};

TeamItem.propTypes = {
  image: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  skillList: PropTypes.array,
  socialList: PropTypes.array.isRequired,
  leftImage: PropTypes.bool,
};

TeamItem.defaultProps = {
  leftImage: true,
  skillList: [],
};

export default TeamItem;
