import React from 'react';
import ImageAndDescription from 'core/image/image-and-description';
import Button from 'components/atomic-design/atoms/button';
import theme from 'styles/theme';
import EntryCardSmall from 'components/atomic-design/molecules/entry-card-small';
import LandingPageSection from 'atomic-design/atoms/landing-page-section';
import { Video } from 'atomic-design/molecules/videos-and-information/styles';
import LandingPageCarousel from '../components/atomic-design/atoms/landing-page-carousel';
import Table from 'atomic-design/atoms/table';
import { parseCarouselClients, parseTable } from 'common/transformers';
import Testimonial from 'atomic-design/molecules/testimonial';
import TestimonialWithCTA from 'atomic-design/molecules/testimonial-with-cta';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Overrun from '../components/atomic-design/organisms/overrun-section';

export const handleImageNode = node => {
  if (node.data.target && node.data.target.fields) {
    const alt = node.data.target.fields.title['en-US'];
    const description = node.data.target.fields?.description
      ? node.data.target.fields?.description['en-US']
      : null;
    const width = node.data.target.metadata?.tags[0]?.sys.id === 'widthSmall' ? '50%' : '100%';
    return (
      <ImageAndDescription
        alt={alt}
        description={description}
        fromContentful
        contentful_id={node.data.target.sys.contentful_id}
        width={width}
        margin="52px 0"
      />
    );
  }
  return (
    <p>
      <b>Image didn't load!</b>
    </p>
  );
};

export const handleEmbeddedEntry = node => {
  let type = getEntryBlockSubtype(node);
  switch (type) {
    case 'callToAction':
      return handleCTANode(node);

    case 'blogPost':
      return handleCSEntryNode(node);

    case 'caseStudy':
      return handleCSEntryNode(node);

    case 'Entry':
      return handleCSEntryNode(node);

    case 'landingPageCarousel':
      return handleLandingPageCarousel(node);

    case 'landingPageSection':
      return handleLandingPageSection(node);

    case 'embeddedYoutubeVideo':
      return handleYoutubeEmbed(node);

    case 'blockquote':
      return handleBlockquote(node);

    case 'testimonialAboutUs':
      return handleTestimonialFeatured(node);

    default:
      return <p>Default Block !</p>;
  }
};

export const handleTableNode = node => {
  const tableData = parseTable(node);
  if (tableData) {
    return <Table {...tableData} />;
  }
  return <p>Error</p>;
};

export const handleLandingPageSection = node => {
  if ((node?.data?.target?.fields?.body && node?.data?.target?.fields?.overrun) ?? false) {
    const content = node?.data?.target?.fields?.body['en-US'];
    const size = node?.data?.target?.fields.layoutSize
      ? node?.data?.target?.fields.layoutSize['en-US']
      : 'sm';
    const background = node?.data?.target?.fields?.background
      ? node?.data?.target?.fields.background['en-US']
      : `${theme.newTheme.color.primary.light}20`;
    return (
      <Overrun
        padding={`${theme.newTheme.spacing(40)} 0 ${theme.newTheme.spacing(30)} 0`}
        background={background}
        margin={`${theme.newTheme.spacing(12)} 0`}
        mobilePadding={`${theme.newTheme.spacing(30)} 0 ${theme.newTheme.spacing(10)} 0`}
        size={size}
        fullWidth
      >
        {documentToReactComponents(content, {})}
      </Overrun>
    );
  } else if (node?.data?.target?.fields?.body) {
    return <LandingPageSection fields={node.data.target.fields} />;
  }

  return <p>Error</p>;
};

export const handleLandingPageCarousel = node => {
  if (node.data.target && node.data.target.fields && node.data.target.fields.carousel) {
    const clients = node.data.target.fields.carousel['en-US'];
    return <LandingPageCarousel clients={parseCarouselClients(clients)} />;
  }
  return <p>Error</p>;
};

export const handleCTANode = node => {
  if (
    node.data.target &&
    node.data.target.fields &&
    node.data.target.fields.text &&
    node.data.target.fields.href &&
    node.data.target.fields.target
  ) {
    const text = node.data.target.fields.text['en-US'];
    const href = node.data.target.fields.href['en-US'];
    const target = node.data.target.fields.target['en-US'];
    return (
      <a aria-label={text} href={href} rel="noreferrer noopener" target={target}>
        <Button>{text}</Button>
      </a>
    );
  }
  return <p>Error</p>;
};

export const handleCSEntryNode = node => {
  if (
    node.data.target &&
    node.data.target.fields &&
    node.data.target.fields.title &&
    node.data.target.fields.publishedDate &&
    node.data.target.fields.slug &&
    node.data.target.fields.subtitle &&
    node.data.target.fields.hero &&
    node.data.target.fields.hero['en-US'].sys &&
    node.data.target.fields.hero['en-US'].sys.contentful_id
  ) {
    const slug = node.data.target.fields.slug['en-US'];
    const title = node.data.target.fields.title['en-US'];
    const publishedDate = node.data.target.fields.publishedDate['en-US'];
    const subtitle = node.data.target.fields.subtitle['en-US'];
    const hero = node.data.target.fields.hero['en-US'].sys.contentful_id;
    const from = node.data.target.sys.contentType.sys.contentful_id;
    let categoryName = '';
    let categoryColor = '';
    if (from === 'blogPost') {
      categoryName = node.data.target.fields.category['en-US'].fields.name['en-US'];
      categoryColor = getCategoryColor(
        node.data.target.fields.category['en-US'].fields.slug['en-US']
      );
    } else {
      categoryColor = '#304BE2';
      categoryName = 'Our Work';
    }
    return (
      <EntryCardSmall
        height="12rem"
        maxWidthText="55%"
        categoryColor={categoryColor}
        categoryName={categoryName}
        hero={hero}
        publishedDate={publishedDate}
        title={title}
        from={from}
        slug={slug}
      ></EntryCardSmall>
    );
  }

  return <p>Error</p>;
};

// Videos are restricted to 50MB in Contentful free plan
/* const handleVideoEmbed = node => {
  const { data } = node;
  if (data.target?.sys?.contentType?.sys?.id === 'blogPostVideo') {
    const src = data.target.fields.videoUrl['en-US'];
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <video controls>
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }
  return null;
}; */
const handleBlockquote = node => {
  const { fields } = node.data?.target;
  const content = fields?.content['en-US'];
  const author = fields?.author['en-US'].fields;
  const image = author?.image?.['en-US']?.sys?.contentful_id;
  const alt = author?.image?.['en-US']?.description;
  return (
    <Testimonial
      isBlockQuote
      image={image}
      alt={alt}
      authorName={author?.name?.['en-US']}
      authorRol={author?.role?.['en-US']}
      description={documentToReactComponents(content, {})}
      quoteColor={theme.newTheme.color.primary.main}
      nameColor={theme.newTheme.color.primary.main}
    />
  );
};

const handleTestimonialFeatured = node => {
  const { fields } = node.data?.target;
  const testimonialLayout = fields?.testimonialLayout && fields?.testimonialLayout['en-US'];
  if (testimonialLayout === 'with-video') {
    const quote = fields?.description['en-US'];
    const author = fields?.author['en-US'].fields;
    const image = author?.image?.['en-US']?.sys?.contentful_id;
    const alt = author?.image?.['en-US']?.description;
    const cta = fields.callToAction['en-US'];
    const title = fields.title['en-US'].content[0].content[0].value;
    return (
      <TestimonialWithCTA
        image={image}
        quote={quote}
        alt={alt}
        title={title}
        authorName={author?.name?.['en-US']}
        authorRol={author?.role?.['en-US']}
        cta={cta}
      />
    );
  } else {
    return <p>Error</p>;
  }
};

export const isInternalUrl = url => {
  const internalUrlRegex = /^(https?:\/\/(www\.)?nan-labs\.com)|^(?!https?:\/\/)/;
  return internalUrlRegex.test(url);
};

const isValidYoutubeUrl = url => {
  const youtubeRegex = /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
  return youtubeRegex.test(url);
};

const extractVideoId = url => {
  if (url.includes('youtu.be/')) {
    // For youtu.be URLs
    return url.split('youtu.be/')[1];
  } else {
    // For regular YouTube URLs
    return url.split('v=')[1];
  }
};

export const handleYoutubeEmbed = node => {
  const { fields } = node.data?.target;
  const videoTitle = fields?.videoTitle['en-US'];
  const youtubeUrl = fields?.youtubeUrl['en-US'];

  if (isValidYoutubeUrl(youtubeUrl)) {
    const videoId = extractVideoId(youtubeUrl);
    return (
      <Video
        src={`https://www.youtube.com/embed/${videoId}`}
        title={videoTitle}
        frameBorder="0"
        allowFullScreen
      />
    );
  }

  return null;
};

const getEntryBlockSubtype = node => {
  return (
    node.data?.target?.sys?.contentType?.sys?.contentful_id ||
    node.data?.target?.sys?.linkType ||
    'default'
  );
};

export const createRangeArray = int => Array.from(new Array(int), (x, page) => page + 1);

export const getAbsoluteUrl = path => `${process.env.GATSBY_SITE_URL}${path}`;

export const addProtocol = url => `https:${url}`;

export const findContentfulAsset = (edges, contentfulId) =>
  edges.find(edge => edge.node.contentful_id === contentfulId);

export const findFluidImage = (data, partialPathName) => {
  const imageFound = data.allImageSharp.edges.find(element => {
    return element.node.fluid.src.includes(partialPathName);
  });
  return imageFound || undefined;
};

export const getCategoryColor = name => {
  const categoriesAndColors = {
    all: `${theme.newTheme.color.tag.all}`,
    'back-end': `${theme.newTheme.color.tag.backend}`,
    devops: `${theme.newTheme.color.tag.devops}`,
    'front-end': `${theme.newTheme.color.tag.frontend}`,
    methodologies: `${theme.newTheme.color.tag.methodologies}`,
    mobile: `${theme.newTheme.color.tag.mobile}`,
    news: `${theme.newTheme.color.tag.news}`,
    generic: `${theme.newTheme.color.primary.light}`,
    ourWork: `${theme.newTheme.color.tag.ourWork}`,
    guide: `${theme.newTheme.color.tag.guide}`,
    'web-technologies': `${theme.newTheme.color.tag.webTechnologies}`,
    enterprise: `${theme.newTheme.color.tag.enterprise}`,
    'client-stories': `${theme.newTheme.color.tag.clientStories}`,
    smb: `${theme.newTheme.color.tag.smb}`,
    agile: `${theme.newTheme.color.tag.agile}`,
    default: `${theme.newTheme.color.tag.default}`,
  };
  return categoriesAndColors[name] || categoriesAndColors.default;
};

export const getRandomPostForAGivenCategory = (posts, category, currentSlug) => {
  const postFound = posts.find(
    post => post.node.category.slug === category && post.node.slug !== currentSlug
  );

  return postFound || posts.find(post => post.node.slug !== currentSlug);
};

export const isServerSideRendering = () => typeof window === 'undefined';

export const matchesToCurrentPathname = path =>
  isServerSideRendering()
    ? false
    : window.location.pathname.toLowerCase().includes(path.toLowerCase());

export const formatDate = date => {
  const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
  return new Date(date).toLocaleDateString('en-US', options);
};

export const lowerCaseRichTextHeaders = headers => {
  const lowerCasedHeaders = headers.content.map(header => {
    if (header.nodeType.includes('heading')) {
      const loweredHeader = header;
      loweredHeader.content[0].value = loweredHeader.content[0].value.toLowerCase();
      return loweredHeader;
    }
    return header;
  });
  return lowerCasedHeaders;
};

export const getCurrentPath = () => (isServerSideRendering() ? '' : window.location.href);

export const getServices = (allServices, serviceName) => {
  let currentService;
  let restOfServices = [];
  let services = [];
  allServices.map(service => {
    if (service.node.title === serviceName) {
      currentService = service.node;
    } else {
      restOfServices.push(service.node);
    }
  });
  services.push(currentService);
  restOfServices.map(service => services.push(service));
  return services;
};

export const getWork = (associatedWork, allContentfulCaseStudy) => {
  return associatedWork
    ? allContentfulCaseStudy.filter(caseStudy => caseStudy.node.slug === associatedWork.slug)[0]
    : allContentfulCaseStudy.filter(edge => edge.node.featured)[0];
};

export const getCategoryArray = categories => {
  const categoriesArray = categories.map(category => {
    return {
      id: category.node.slug,
      label: category.node.name,
      to: `/blog/category/${category.node.slug}`,
    };
  });
  return [{ id: 'All', label: 'All', to: '/blog' }, ...categoriesArray];
};
