import React from 'react';
import PropTypes from 'prop-types';
import { Description } from './styles';

const ImgDescription = ({children}) => {

  return (
    <Description>{children}</Description>
  );
};

ImgDescription.propTypes = {
  children: PropTypes.string.isRequired,
};

export default ImgDescription;
