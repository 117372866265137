import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(6)};
  padding: 0;
  justify-content: center;
  ${({ leftImage }) => (leftImage ? 'flex-direction:  row' : 'flex-direction:  row-reverse')};

  h3 {
    color: ${({ theme }) => theme.newTheme.color.primary.main};
    font-size: ${({ theme }) => theme.newTheme.font.title6.size};
    line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
    margin-top: 0 !important;
    font-weight: 600;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

export const ImgMainContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const ImgContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 550px;
  min-height: 550px;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    min-width: 0;
    min-height: 0;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    min-width: 0;
    min-height: 0;
    img {
      flex-shrink: 0;
    }
  }
`;

export const InformationContainer = styled.div`
  position: relative;
  height: 100%;
  width: 50%;
  ${({ theme }) => `padding: 0 ${theme.newTheme.spacing(8)} ${theme.newTheme.spacing(8)}`};

  h4:nth-child(1) {
    margin-top: 0;
    color: ${({ theme }) => theme.newTheme.color.primary.main};
    font-size: ${({ theme }) => theme.newTheme.font.title6.size};
    line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      margin: ${({ theme }) => theme.newTheme.spacing(4)} 0;
    }
  }

  h4:nth-child(3) {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    margin-top: ${({ theme }) => theme.newTheme.spacing(2)};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
    padding: 0;
  }
`;

export const SkillText = styled.p`
  font-size: ${({ theme }) => `${theme.newTheme.font.text3.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.text3.lineHeight}`};
  color: ${({ theme }) => `${theme.newTheme.color.grey.main}`};
`;

export const RoleText = styled.h4`
  color: ${({ theme }) => `${theme.newTheme.color.grey.main}`};
  margin: 0;
`;

export const DescriptionText = styled.h4`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(6)};
`;

export const NetworkContainer = styled.div`
  transform: translateX(-5px);
  margin-top: ${({ theme }) => theme.newTheme.spacing(4)};
  height: 100%;

  li {
    display: inline-block;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    padding: ${({ theme }) => `${theme.newTheme.spacing(3)} 0`};
  }
`;
