import React from 'react';

import {
  ImgContainer,
  PreTitle,
  GradientTitle,
  Container,
  ImgAndTextContainer,
  BoxesContainer,
} from './styles';
import { AroundTheWorld, FeedbackRating, HandsClasping, ManyPeople, Rocket, Scale } from 'ui/svg';
import PageSection from 'atomic-design/atoms/page-section';
import theme from '../../../../styles/theme';

const AboutUsInfo = () => (
  <PageSection
    margin={'0 auto'}
    background={theme.newTheme.color.background.paleLightBlue}
    options="fullWidth"
    padding={`${theme.newTheme.spacing(10)} 0 ${theme.newTheme.spacing(13)} 0`}
  >
    <Container>
      <PreTitle>About Us</PreTitle>
      <GradientTitle>
        Your sidekick in crafting top-notch software solutions that drive revenue growth and
        operational efficiency.
      </GradientTitle>
      <BoxesContainer>
        <ImgAndTextContainer>
          <ImgContainer>
            <HandsClasping />
          </ImgContainer>
          <h4>
            <b>+11</b> years partnering with brands like yours
          </h4>
        </ImgAndTextContainer>

        <ImgAndTextContainer>
          <ImgContainer>
            <AroundTheWorld />
          </ImgContainer>

          <h4>From South America, we're also in the US & Europe</h4>
        </ImgAndTextContainer>

        <ImgAndTextContainer>
          <ImgContainer>
            <ManyPeople />
          </ImgContainer>

          <h4>
            <b>+80 </b> talented experts in one unified and global team
          </h4>
        </ImgAndTextContainer>
        <ImgAndTextContainer>
          <ImgContainer>
            <Scale />
          </ImgContainer>
          <h4>
            <b>+3</b> years average tenure
          </h4>
        </ImgAndTextContainer>

        <ImgAndTextContainer>
          <ImgContainer>
            <FeedbackRating />
          </ImgContainer>

          <h4>
            A strong NPS with <b>85</b> points
          </h4>
        </ImgAndTextContainer>

        <ImgAndTextContainer>
          <ImgContainer>
            <Rocket />
          </ImgContainer>

          <h4>
            <b>+100</b> projects successfully launched
          </h4>
        </ImgAndTextContainer>
      </BoxesContainer>
    </Container>
  </PageSection>
);

export default AboutUsInfo;
